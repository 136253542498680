import React from "react"
import { Card } from "react-bootstrap"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

type Props = {
  backgroundColor: string
  children: any,
  href: string,
  icon: IconProp,
  iconSize: FontAwesomeIconProps["size"]
}

const ContactCard = (props: Props) => (
  <a href={props.href} target="_blank" style={{textDecoration: "none"}}>
      <div className="d-flex justify-content-center mb-3">
        <div className="d-flex justify-content-center align-items-center p-4"
             style={{
               backgroundColor: "rgba(33, 33, 33, 0.9)",
               color: "rgba(255,255,255,0.9)",
               boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
               borderRadius: 50,
               width: 100,
               height: 100}}>
          <FontAwesomeIcon icon={props.icon} size={props.iconSize} style={{margin: "0px auto"}}/>
        </div>
      </div>
      <div className="text-center">
        {props.children}
      </div>
  </a>
)

export default ContactCard